import { useContext } from "react";
import i18n from '../util/translation';
import { AuthContext } from "../context";

export const urlBackend = process.env.REACT_APP_BASE + '/';
export const hJwtAxios = { 'Authorization': 'Bearer ' + localStorage.getItem('id_token') };

export function formatPrice(cents) {
	return `R$${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function rando(arr) {
	return arr[Math.floor(Math.random() * arr.length)];
}

// export function slugify(text) {
//   return text.toString().toLowerCase()
//     .replace(/\s+/g, '-')           // Replace spaces with -
//     .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
//     .replace(/\-\-+/g, '-')         // Replace multiple - with single -
//     .replace(/^-+/, '')             // Trim - from start of text
//     .replace(/-+$/, '');            // Trim - from end of text
// }

export function isLoading(state) {
	if (state === 'block') document.querySelector('.js-loading__overlay').classList.remove('display-none');
	if (state === 'none') document.querySelector('.js-loading__overlay').classList.add('display-none');
}

var timer = null;
export function debounce(func, counter = 400) {
	clearTimeout(timer);
	timer = setTimeout(func, counter);
}
// export function checkNullItem(item) {
// 	return item || item == 0 ? item : '';
// }
export function titlePage(title) {
	if (title === 'remove') {
		document.querySelector('#js-title').classList.add('display-none');
		return;
	}
	document.querySelector('#js-title').classList.remove('display-none');
	document.querySelector('#js-title').innerHTML = title;
	document.title = 'Bortolini - ' + title;
}

export function cardInfo(state, text) {
	if (state === 'error') {
		let card = document.querySelector('.js-card__info');
		card.classList.remove('display-none');
		card.classList.add('error');
		card.innerHTML = text;
	}
	if (state === 'success') {
		let card = document.querySelector('.js-card__info');
		card.classList.remove('display-none');
		card.classList.add('success');
		card.innerHTML = text;

	}
	if (state === 'remove') {
		let card = document.querySelector('.js-card__info');
		card.classList.add('display-none');
		card.classList.remove('error');
		card.classList.remove('success');
	}
}
export function toastInfo(state, text) {
	let toast = document.querySelector('#js-toast');
	if (state === 'error' && text === 'custom') text = 'Houve um erro, tente novamente mais tarde.';
	if (state === 'success' && text === 'save') text = 'Salvo com sucesso!';
	if (state === 'success' && text === 'change') text = 'Alterado com sucesso!';
	toast.classList.add(state);
	toast.innerHTML = text;
	setTimeout(() => {
		toast.classList.remove(state);
	}, 4500);
}

// export function removeSpaceArray(arr) {
// 	return arr.filter((item) => item != "");
// }

export function brDate(date) {
	let onlyDate = date.substring(0,10);
	onlyDate = onlyDate.split('-').reverse().join('/');
	let onlyTime = date.substring(11,16);
	return onlyTime + ' - ' + onlyDate;
}

export function usDate(date) {
	let onlyTime = date.substring(0,8);
	let onlyDate = date.substring(9,19);
	onlyDate = onlyDate.split('/').reverse().join('-');
	return onlyDate + ' ' + onlyTime;
}
export function goDateToBr(date) {
	console.log('====================================');
	console.log(date);
	console.log('====================================');
	// let onlyDate = date.substring(0,10);
	// let onlyTime = date.substring(11,19);
	// onlyDate = onlyDate.split('-').reverse().join('/');
	// return onlyTime + ' ' + onlyDate;
}

export function convertDateToGo(string) {
	return new Date(string).toISOString();
}

//pega a data de um campo e a hora de outro e converte em um somente
// export function mergeDateToGo(dateParam, hourParam) {
// 	let pad = function(num) { return ('00'+num).slice(-2) };
// 	let date = new Date(dateParam);
// 	let hour = new Date(hourParam);
// 	let now  = new Date();
// 	let dateOnly = date.getFullYear() == 0 ? now.getFullYear() : date.getFullYear() + '-' +
// 			 	   pad(date.getMonth() + 1) + '-' +
// 			 	   pad(date.getDate());

// 	let hourOnly = pad(hour.getHours()) + ':' +
// 				   pad(hour.getMinutes()) +
// 				   ':00.00-03:00';
// 	let newDate = dateOnly + 'T' + hourOnly;
// 	return new Date(newDate).toISOString();
// }
export function convertDateISOToBR(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return pad(date.getHours()) + ':' + pad(date.getMinutes()) + ' - ' + pad(date.getDate()) + '/' + pad(date.getMonth() + 1) + '/' + date.getFullYear();
}
export function convertDateISOToBROnlyDate(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return pad(date.getDate()) + '/' + pad(date.getMonth() + 1) + '/' + date.getFullYear();
}
export function convertDateISOToBROnlyHour(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return  pad(date.getHours()) + ':' + pad(date.getMinutes());
}

export function convertDateISOToMaterialUIFormatDateTime(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
}
export function convertDateISOToMaterialUIFormatTime(string) {
	return string.slice(11, 16);
}
export function convertDateISOToMaterialUIFormatDate(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}
export function convertDateISOToFormatVisualDateTime(string) {
	let date = new Date(string);
	let pad = function(num) { return ('00'+num).slice(-2) };
	return `${pad(date.getHours())}:${pad(date.getMinutes())} ${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;

}
export function convertWeekdayStringToNumber(arr) {
	var newArr = [];
	arr.map((el, i) => {
		if(el == 'Domingo') 	  newArr.push(0);
		if(el == 'Segunda-Feira') newArr.push(1);
		if(el == 'Terça-Feira')   newArr.push(2);
		if(el == 'Quarta-Feira')  newArr.push(3);
		if(el == 'Quinta-Feira')  newArr.push(4);
		if(el == 'Sexta-Feira')   newArr.push(5);
		if(el == 'Sábado')        newArr.push(6);
	});
	return newArr;
}
export function convertTimeMaterialToGo(string) {
	return `2000-01-01T${string}:00-03:00`;
}
export function convertDateTimeMaterialToGoRealtorRestriction(date) {
	var x = (new Date()).getTimezoneOffset() * 60000;
	return (new Date(new Date(date.replace('T', ' ')) - x)).toISOString().slice(0,-5) + '-03:00';
}
export function convertDateTimeMaterialToGo(date, hour) {
	var x = (new Date()).getTimezoneOffset() * 60000;
	return (new Date(new Date(date + ' ' + hour) - x)).toISOString().slice(0,-5) + '-03:00';
}
export function convertDateTimeUnionMaterialToGo(string) {
	var x = (new Date()).getTimezoneOffset() * 60000;
	return (new Date(new Date(string.replace('T', ' ')) - x)).toISOString().slice(0,-5) + '-03:00';
}
export function numberDayToString(weekday) {
	switch (weekday) {
		case 0:
			return 'Domingo'
		case 1:
			return 'Segunda-Feira'
		case 2:
			return 'Terça-Feira'
		case 3:
			return 'Quarta-Feira'
		case 4:
			return 'Quinta-Feira'
		case 5:
			return 'Sexta-Feira'
		case 6:
			return 'Sábado'
		default:
			break;
	}
}
// export function compareArrays(a1, a2) {

//     var a = [], diff = [];

//     for (var i = 0; i < a1.length; i++) {
//         a[a1[i]] = true;
//     }

//     for (var i = 0; i < a2.length; i++) {
//         if (a[a2[i]]) {
//             delete a[a2[i]];
//         } else {
//             a[a2[i]] = true;
//         }
//     }

//     for (var k in a) {
//         diff.push(k);
//     }

//     return diff;
// }

export function compareRealtor(a, b) {
	const nomeA = a.Name.toUpperCase();
	const nomeB = b.Name.toUpperCase();
	let comparison = 0;
	if (nomeA > nomeB) {
			comparison = 1;
	} else if (nomeA < nomeB) {
			comparison = -1;
	}
	return comparison;
}
export function downloadItem(url) {
	isLoading('block');
	var link = document.createElement('a');
	link.href = url;
	link.download = url;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	isLoading('none');
}
export function handleDateJStoSql(date) {
	var month = date.getUTCMonth() + 1; //months from 1-12
	var day = date.getUTCDate();
	var year = date.getUTCFullYear();
	return year + "-" + month + "-" + day;
}
export function capitalize(s){
    return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
};
export function mountNameTitle(string) {
	string = string.substring(1);
	let allItemsString = string.split('/');
	// let newString = "";
	// allItemsString.map(el => {
	// 	newString = newString + " " + el;
	// })
	return allItemsString[0];
	// let allItemsString = string.split('-');
	// let newString = "";
	// allItemsString.map(el => {
	// 	newString = newString + " " + el;
	// })
	// return newString;
}

export const T = (label) => {
	const { systemLang } = useContext(AuthContext);
	return i18n.t(label, {lng: systemLang()});
}

export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export function compareTwoArrayOfObj(arr1, arr2) {
	function comparerA(otherArray) {
		return function (current) {
			return otherArray.filter(function (other) {
				return other.EventID == current.ID
			}).length == 0;
		}
	}
	function comparerB(otherArray) {
		return function (current) {
			return otherArray.filter(function (other) {
				return other.ID == current.EventID
			}).length == 0;
		}
	}

	return {
		onlyInA: arr1.filter(comparerB(arr2)),
		onlyInB: arr2.filter(comparerA(arr1))
	}
}

export function getActualDateToTable(initial, input = false) {
	let date = new Date();
	let day = date.getUTCDate();
	let year = date.getUTCFullYear();
	let month = date.getUTCMonth() + 1;
	let hour = "T00:00:00-03:00";


	if(!initial) {
        var d = new Date(year, month, 0);
		day = d.getUTCDate();
		hour = "T23:59:59-03:00";
	}
	let pad = function(num) { return ('00'+num).slice(-2) };
	if(input) return year + "-" + pad(month) + "-" + pad(day);
	return year + "-" + pad(month) + "-" + pad(day) + hour;
}
