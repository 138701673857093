import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { debounce } from './helpers';
import { useToast } from './ToastProvider'

//<table>
	import MUIDataTable from "mui-datatables";
	import DeleteIcon from "@material-ui/icons/Delete";
	import Tooltip from "@material-ui/core/Tooltip";
	import IconButton from "@material-ui/core/IconButton";
	import EditIcon from '@material-ui/icons/Edit';
	import { NavLink } from 'react-router-dom';
	import AddIcon from '@material-ui/icons/Add';
	import ImportExportIcon from '@material-ui/icons/ImportExport';
//</table>


const TableAdac = ({urlApi, urlFrontend = urlApi, columnsArr, funcsColumn = {}, pagination = false, isReport = false, startAt = false, endAt = false, filter = false}) => {
	const { handleOpenToast } = useToast();

	const [data, setData] = useState([]);
	
	const [limitPage]                           = useState(15);
	const [actualPage,      setActualPage]      = useState(0);
	const [countItemns,     setCountItemns]     = useState(0);
	const [lastItemSorted,  setLastItemSorted]  = useState('ID');
	const [requestItems,    setRequestItems]    = useState({
															isSearch: '',
															lastOrderSorted: 'asc',
															idRealtors: ''
													  	   });


	const handleDelete = (rowsDeleted) => {
        const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex]);
        idsToDelete.map((el) => {
            Axios.delete(
                urlApi + '/' + el.ID,
            ).then(response => {
				changePage();
            }).catch(error => {
                console.log("erro", error, error.response);
            });
        });
	};

	const changePage = (type, haveSearch) => {
		let insLastOrder = null; 
		let insHaveSearch = '';

		if(haveSearch) {
			insHaveSearch = haveSearch;
		}

		if(type && type != 'changePage') {
			insLastOrder = requestItems.lastOrderSorted == 'asc' ? 'desc' : 'asc';
			setLastItemSorted(type);
		}
		
		setRequestItems({
			lastOrderSorted: insLastOrder != null ? insLastOrder : requestItems.lastOrderSorted,
			isSearch: insHaveSearch,
		});
	};

	useEffect(() => {
		Axios.get(
		   `${urlApi}?page=${actualPage}&limit=${limitPage}&order=${lastItemSorted == 'search' ? 'id' : lastItemSorted} ${requestItems.lastOrderSorted}${requestItems.isSearch ? '&search='+ requestItems.isSearch : '&search='}${startAt ? '&start_at='+ startAt : ''}${endAt ? '&end_at='+ endAt : ''}&realtors=${requestItems.idRealtors ? requestItems.idRealtors : ''}`,
	   ).then(response => {
			let realData = response.data || [];
			let realDataList;
			let realDataCount = 0;

			if(pagination) {
				realDataList = realData.List;
				realDataCount = realData.Count;
			} else {
				realDataList = realData;
				realDataCount = realData.length;
			}
			if(funcsColumn != {}) {
				realDataList.map((el, i) => {
					Object.keys(funcsColumn).map(function(key) {
						el[key] = funcsColumn[key](el);
					});
				});
			}
			setData(realDataList);
			setCountItemns(realDataCount);
	   }).catch(error => {
			handleOpenToast('Erro, tente novamente.', 'error', 2000, true)
			console.log("erro", error, error.response);
	   });
   }, [requestItems])
	
   	const columns = [];

	Object.keys(columnsArr).map((i) => {
		columns.push(columnsArr[i]);
	});

	columns.push({
		name: "Edição",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<>
						<NavLink to={`/${urlFrontend}/edit/` + tableMeta.rowData[0]}>
							<EditIcon titleAccess="Edição" />
						</NavLink>
						{
							isReport
						?
							<>
								&nbsp;
								&nbsp;
								<NavLink to={`/${urlFrontend}/edit/${tableMeta.rowData[0]}?isExport`}>
									<ImportExportIcon titleAccess="Exportar" />
								</NavLink>
							</>
						:
							null
						}
					</>
				);
			}
		}
	});


	const options = {
		filterType: 'checkbox',
		filter: filter ? true : false,
		print: false,
		search: true,
		searchText: requestItems.isSearch,
		download: false,
		rowsPerPage: limitPage,
		rowsPerPageOptions: [],
		selectableRows: "multiple",
		selectableRowsOnClick: true,
		serverSide: true,
		count: countItemns,
		page: actualPage,
		filterType: 'multiselect',
		pagination: pagination ? true : false,
		enableNestedDataAccess: '.',
		customToolbarSelect: (selectedRows) => {
			return (
				<div>
					<Tooltip title={"Deletar Items"}>
						<IconButton onClick={() => handleDelete(selectedRows)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</div>
			)
		},
		//paginator
		onTableChange: (action, tableState) => {
			setActualPage(tableState.page);
			switch (action) {
			  case 'changePage':
				changePage('changePage', requestItems.isSearch);
				break;
			}
		},
		//sorting
		onColumnSortChange: (changedColumn, direction) => {
			changePage(changedColumn.toLowerCase(), requestItems.isSearch);
		},
		//searching
		onSearchChange: (searchQuery) => {
			debounce(() => {changePage('search', searchQuery)});
		},
		// filter
		onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
			let allIDsSelected = [];
			let alreadyPass = false;
			filterList[2].map(el => {
				let separated = el.split("|");
				allIDsSelected.push(separated[0].trim());
				alreadyPass = true;
			})
			setRequestItems({
				lastOrderSorted: requestItems.lastOrderSorted,
				isSearch: requestItems.insHaveSearch,
				idRealtors: allIDsSelected.toString()
			});


		},
		textLabels: {
			body: {
				noMatch: "Desculpe, nenhum registro encontrado.",
				toolTip: "Ordenar",
				columnHeaderTooltip: column => `Ordernar por ${column.label}`
			},
			pagination: {
				next: "Proxima página",
				previous: "Página Anterior",
				rowsPerPage: "Linhas por página:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscar",
				downloadCsv: "Download CSV",
				print: "Imprimir",
				viewColumns: "Ver colunas",
				filterTable: "Filtrar tabela",
			},
			filter: {
				all: "Tudo",
				title: "Filtros",
				reset: "",
			},
			viewColumns: {
				title: "Mostrar Colunas",
				titleAria: "Mostrar/esconder colunas das tabelas",
			},
			selectedRows: {
				text: "linha(s) seleciona(das)",
				delete: "Inativar linhas",
				deleteAria: "Delete Selected Rows",
			},
		}
	};

    return (
		<MUIDataTable
			title={
				<NavLink to={`/${urlFrontend}/edit/new`}>
					<AddIcon style={{ fontSize: 35 }} />
				</NavLink>
			}
			data={data}
			columns={columns}
			options={options}
		/>
	);
};

export default TableAdac;