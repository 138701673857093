// para pegar icones, pega algum do site abaixo e aonde tiver camel case coloca um _
// Ex PersonAdd > person_add
// https://material-ui.com/components/material-icons/

export const items = [
    {
        title: 'Dashboard',
        menus: [ 
            {
            title: 'dashboard',
            ico: 'dashboard',
            to: "/dashboard",
            onlyAdmin: false,
            separator: true
        },
          
            
        ]
    },
    {
        title: 'Corretor',
        menus: [ 
            {
                title: 'corretor',
                ico: 'person',
                to: "/corretor",
                onlyAdmin: true,
                separator: true
            },
            {
                title: 'Restrição do Corretor',
                ico: 'people',
                to: "/restricao-do-corretor",
                onlyAdmin: true,
                separator: true
            },
          
            
        ]
    },
    {
        title: 'controle',
        menus: [
            {
                title: 'Eventos',
                ico: 'business',
                to: "/eventos",
                onlyAdmin: true,
                separator: true
            },
            {
                title: 'Cronograma',
                ico: 'assessment',
                to: "/cronograma",
                onlyAdmin: true,
                separator: true
            },
            {
                title: 'Restrições Gerais',
                ico: 'today',
                to: "/restricao-geral",
                onlyAdmin: true,
                separator: true
            },
            {
                title: 'Diarias',
                ico: 'today',
                to: "/diarias",
                onlyAdmin: true,
                separator: true
            },

        ]
    },
    // {
    //     title: 'Relatório',
    //     menus: [
    //         {
    //             title: 'Relatórios',
    //             ico: 'assessment',
    //             to: "/relatorio",
    //             onlyAdmin: true,
    //             separator: true
    //         },
    //     ]
    // },
    {
        title: 'administrativo',
        menus: [
            {
                title: 'usuarios',
                ico: 'person',
                to: "/usuario",
                onlyAdmin: true
            },
        ]
    }
];