import React, { Fragment, useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { items } from "./data";
import { Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { mountNameTitle, T } from '../../util/helpers';

import CircularProgress from '@material-ui/core/CircularProgress';
import icoLogo from '../../assets/img/ic-logo.png';
import icoLogoHorizontal from '../../assets/img/ic-logo.svg';

import { useHistory } from 'react-router-dom';

import i18n from '../../util/translation';
import { AuthContext } from "../../context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    popover: {   
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        scrollPadding: '0 20'
    },
    link: {
        padding: 10,
        display: 'block',
        textDecoration: 'none',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    brandTitle: {
        display: 'flex',
        margin: '0 auto'
        // justifyContent: 'center'
    },
    brandTitleImg: {
        maxWidth: 100,
        // margin: '0 auto'
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const Menu = (props) => {


    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const service = useContext(AuthContext);
    
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userRole, setUserRole] = useState(false);
    const [namePage, setNamePage] = useState(null);
    const [allMenus, setAllMenus] = useState(null);

    const setPageName = () => {
        setNamePage(mountNameTitle(history.location.pathname));
    }
    useEffect(() => {
        setPageName();
        setUserRole(localStorage.getItem('@Bortolini::is_admin') == 'true');
    }, []);
    
    

    history.listen(() => {
        setPageName();        
    })
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        const menus = Object.keys(items).map((keyName) => {
            return (
                <List className="menu-list js-menu-father" key={keyName}>
                    <b className="sidemenu--b">{items[keyName].title}</b>
                        {
                            Object.keys(items[keyName].menus).map((keyNameSub) => {
                                if(!userRole && items[keyName].menus[keyNameSub].onlyAdmin) {
                                    return null;
                                } else {        
                                    return (
                                        <Fragment key={keyNameSub}>
                                            <NavLink className="sidemenu--a" activeClassName="menu--active" to={items[keyName].menus[keyNameSub].to} onClick={() => setMobileOpen(false)}>
                                                <ListItem key={keyNameSub} className="menu-child">
                                                    <Icon>{items[keyName].menus[keyNameSub].ico}</Icon>
                                                    {/* <NavLink className="navbar-item" activeClassName="menu--active" to={items[keyName].menus[keyNameSub].to}>{t(items[keyName].menus[keyNameSub].title)}</NavLink> */}
                                                        <ListItemText className="--text" primary={i18n.t(items[keyName].menus[keyNameSub].title, {lng: service.systemLang()})} />
                                                </ListItem>
                                            </NavLink>
                                            {items[keyName].menus[keyNameSub].separator ? <Divider /> : null}
                                        </Fragment>
                                    )
                                }
                            })
                        }
                </List>
            )
        });
        setAllMenus(menus);
    }, [userRole])

    const drawer = (
        <div>
            <div className={classes.toolbar} style={{display: 'flex', alignItems: 'center'}}>
            <NavLink className={classes.brandTitle} to={'/dashboard'} >
                <img className={classes.brandTitleImg} src={icoLogoHorizontal} />
            </NavLink>
                {/* <p className={classes.brandTitle}>
                </p>                 */}
            </div>
            <Divider />
            {/* { menus }; */}
            { allMenus };
        </div>
    );

    //   const container = window !== undefined ? () => window().document.body : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{flexGrow: 1,}} variant="h6" noWrap>
                        {T(namePage)}
                        {/* {i18n.t(namePage, {lng: service.systemLang()})} */}
                        {/* {namePage} */}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <AccountCircle fontSize="large" />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className={classes.popover}>
                            {/* <Link to="/current-user" className={classes.link}>Usuário</Link> */}
                            <Link to="/deslogar" className={classes.link}>Logout</Link>
                        </div>
                    </Popover>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        // container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {/* <Typography variant="h5" className="capitalize">
                    {T(namePage)} 
                </Typography>
                <Divider variant="middle" className="m-v-lg-imp" />  */}
                <div className="loader js-loader display-none">
                    <CircularProgress
                        size={80}
                        thickness={4}
                    />
                    <img src={icoLogo} />
                </div>
                {props.page}
            </main>
        </div>
    );
}

export default Menu;
