import React from 'react';
import { useParams } from 'react-router-dom';

import RealtorRestrictionListPage from './RealtorRestrictionList';
import RealtorRestrictionEditPage from './RealtorRestrictionEdit';

const RealtorRestrictionPage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <RealtorRestrictionEditPage id={params.id} />
	} else {
		return <RealtorRestrictionListPage />	
	}
};

export default RealtorRestrictionPage;