import React from 'react';
import TableAdac from '../../../util/TableAdac';

const UserListPage = () => {
    return (
		<TableAdac
			urlApi="user"
			urlFrontend="usuario"
			columnsArr={[
				{
					name: 'ID',
					label: 'ID',
					options: {
						filter: true,
						sort: true
				   	}
				},
				{
					name: 'Name',
					label: 'Nome',
					options: {
						filter: true,
						sort: true
					}
				},
				{
					name: 'Email',
					label: 'Email',
					options: {
						filter: true,
						sort: true
					}
				},
			]}
		/>
	);
};

export default UserListPage;