import React from 'react';
import TableAdac from '../../../util/TableAdac';
import { convertDateISOToMaterialUIFormatTime, numberDayToString, convertDateISOToBROnlyDate } from '../../../util/helpers';

const ScheduleListPage = () => {

    return (
		<TableAdac
			urlApi="schedule"
			urlFrontend="cronograma"
			columnsArr={[
				{
					name: "ID",
					label: "ID",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Name",
					label: "Nome",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Weekday",
					label: "Dia",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "StartAt",
					label: "Início",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "EndAt",
					label: "Final",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "RequiredRealtor",
					label: "Quantidade de pessoas",
					options: {
						filter: true,
						sort: true,
					}
				},
			]}
			funcsColumn={{
				Weekday: (el) => el.Weekday != null ? numberDayToString(el.Weekday) : convertDateISOToBROnlyDate(el.StartAt),
				StartAt: (el) => convertDateISOToMaterialUIFormatTime(el.StartAt),
				EndAt: (el) => convertDateISOToMaterialUIFormatTime(el.EndAt),
			}}
		/>
	);
};

export default ScheduleListPage;