
import decode from 'jwt-decode';
import axios from 'axios';



export default class AuthService {
    constructor() {
        this.domain = process.env.REACT_APP_BASE
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
    }
    
    login(email, password, isRealtor) {
        // <fake>
        // var res = {success: true, token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFbWFpbCI6I…Tk3fQ.kY3yoTKGi_lpses4KjLldRyXeYlCUqpy0NdkmtsIjAU", user_email: "c@ssiano.tk", user_name: "Cassiano Scheidemantel", admin:true, id:6};
        // this.setToken(res.token);
        // store.dispatch(infoUser(res.user_name));
        // store.dispatch(emailUser(res.user_email));
        // store.dispatch(adminUser(res.admin));
        // store.dispatch(idUser(res.id));
        // axios.defaults.headers.Authorization = `Bearer ${res.Token}`
        // toastInfo('success', `Bem vindo, ${res.user_name}!`);
        // window.resFromAuth = res;
        // return Promise.resolve(res);

        // if(1 == 2) {
        // </fake>
        // Get a token
        let url = this.domain + '/login'; 
        return axios.post(
                url,
                {
                    email: email,
                    password: password 
                }).then(res => {
                    res = res.data;
                    this.setToken(res.Token);
                    // let isAdmin = res.Type == 'ADMIN' ? true : false;
                    // store.dispatch(adminUser(isAdmin));
                    
                    axios.defaults.headers.Authorization = `Bearer ${res.Token}`;
                    
                    // toastInfo('success', `Bem vindo, ${res.Name}!`);
                    window.resFromAuth = res;
                    return Promise.resolve(res);
                }).catch(error => {
                    window.resFromAuth = error;
                });
            // <fake>
            // }
            // </fake>
        }
        
        loggedIn() {
            // Checks if there is a saved token and it's still valid
            const token = this.getToken();
            if (!!token && !this.isTokenExpired(token)) {
                // signIn(token);
                return !!token && !this.isTokenExpired(token); // handwaiving here
            }
        }
        
        isTokenExpired(token) {
            
            try {
                const decoded = decode(token);
                if (decoded.exp < Date.now() / 1000) {
                    // signOut();
                    // isLoading('none');
                    return true;
                } else {
                    // signIn(token);
                    return false;
                }
            }
            catch (err) {
                return false;
            }
        }
        
        setToken(idToken) {
            // Saves user token to localStorage
            localStorage.setItem('@Bortolini::id_token', idToken);
        }
        
        getToken() {
            // Retrieves the user token from localStorage
            return localStorage.getItem('@Bortolini::id_token')
        }
        
        logout() {
            // Clear user token and profile data from localStorage
            localStorage.removeItem('@Bortolini::id_token');
            localStorage.removeItem('@Bortolini::is_admin');
        }
        
        // getProfile() {
        //     return decode(this.getToken());
        // }
        
        
        // fetch(url, options) {
        //     // performs api calls sending the required authentication headers
        //     const headers = {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }
            
        //     if (this.loggedIn()) {
        //         headers['Authorization'] = 'Bearer ' + this.getToken()
        //     }
            
        //     return fetch(url, {
        //         headers,
        //         ...options
        //     })
        //     .then(this._checkStatus)
        //     .then(response => response.json())
        // }
        
        // _checkStatus(response) {
        //     // raises an error in case response status is not a success
        //     if (response.status >= 200 && response.status < 300) {
        //         return response
        //     } else {
        //         var error = new Error(response.statusText)
        //         error.response = response
        //         throw error
        //     }
        // }
    }