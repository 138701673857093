import React, {useState, useEffect, useMemo} from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, Redirect } from 'react-router-dom';

import ToastProvider from './util/ToastProvider'

//<Auth>
    import axios from 'axios';
    import { AuthContext } from "./context";
    import AuthService from './auth/AuthService';
//</Auth>

//<menus>

    // login
        import { LoginPage } from './pages/auth/login';
    // login
    // menu
        import { Menu } from "./pages/menu";
    // menu

    // dashboard
        import { DashboardPage } from './pages/dashboard/normal';
        import { DashboardPublicPage } from './pages/dashboard/public';
    // dashboard
    

    
    import { EventPage } from './pages/control/event';
    
    
    
    import { SchedulePage } from './pages/control/schedule';
    import { DiariesPage } from './pages/control/diaries';
    import { GeneralRestrictionsPage } from './pages/control/general-restrictions';
    
    import { RealtorPage } from './pages/realtor/realtor';
    import { RealtorRestrictionPage } from './pages/realtor/RealtorRestriction';
    
    import { ReportPage } from './pages/report';


    // user
        import { UserPage } from './pages/admin/user';
    // user

    // not-found
        import { NotFoundPage } from './pages/extra/notFound';
    // not-found
//</menus>


axios.interceptors.request.use(req => {
    let loader = document.querySelector('.js-loader');
    if(loader) loader.classList.add('display-flex-imp');
    return req;
});
//will handle with all the request inside the app
//if any of it have the status 401(permission unathourized) will kick the user out
axios.interceptors.response.use(res => {
    let loader = document.querySelector('.js-loader');
    if(res && loader) loader.classList.remove('display-flex-imp');
    return res;
}, err => {
    let loader = document.querySelector('.js-loader');
    if(err && loader) loader.classList.remove('display-flex-imp');
	if (err.response && err.response.status === 401) {
        localStorage.removeItem('@Bortolini::id_token');
        localStorage.removeItem('@Bortolini::is_admin');
        delete axios.defaults.headers.Authorization;
        window.location.href = '/login';
    }
    return err;
	// if (err.response.status === 500) {
	// 	console.log('====================================');
    //     console.log('aoba');
    //     console.log('====================================');
	// }
})  

function App() {

    //instance the authService
    const Auth = new AuthService();
    //instance the history
    const history = useHistory();

    //create the token
    const [userToken, setUserToken] = useState(null);
    const [lang, setLang] = useState('pt');
    const [allLangs, setAllLangs] = useState('en', 'es', 'pt');
    const [isAdmin, setIsAdmin] = useState(false);

    //use the context to create a single signIn and signOut
    const authContext = useMemo(() => {
		return {
			signIn: (token, shouldGoToDashboard = false) => {
                setUserToken(token);
                if(shouldGoToDashboard) {
                    window.location.href = '/dashboard';
                }
			},
			signOut: (shouldGoToLogin = false) => {
                Auth.logout();
                setUserToken(null);
                if(history.location.pathname == '/' || shouldGoToLogin) {
                    window.location.href = '/login';
                }
            },
            systemLang: (langText = false) => {
                if(langText) setLang(langText);
                // return lang;
            },
            setAdmin: (admin) => {
                if(admin) setIsAdmin(admin);
            }
        }
    // eslint-disable-next-line
	}, [])
    
    
	useEffect(() => {
		const handleAuth = async () => {
            //check if the session has a token
            const userToken = await localStorage.getItem('@Bortolini::id_token');

            //if has will manage the token else will logout the user
            if(userToken){
                //add the token in all the requests
                axios.defaults.headers.Authorization = `Bearer ${userToken}`;
                
                //chekc if user token is invalid
                const tokenExpired = await Auth.isTokenExpired(userToken);
                //if the response is true will sign the user out
                if(tokenExpired) {
                    authContext.signOut();
                } else {
                    authContext.signIn(userToken);
                }
            } else {
                authContext.signOut();
            }
		}
        handleAuth();
    // eslint-disable-next-line
	}, []) 


    //authentication routes
    const AuthStack = () => (
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/dashboard-publico/:token" component={DashboardPublicPage} />
            <Route component={NotFoundPage} />
        </Switch>
    );

    //general routes inside app
    const AppStack = () => (
        <Switch>
            <Route exact path="/login">
                <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/signup">
                <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={DashboardPage} />
            {/* http://localhost:3000/dashboard-publico/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDg0MjY3NzQsIlJlYWx0b3JJRCI6NTJ9.fgUQilhxCSwf4x9baIsK8jQQwmrrED8O4aZe4Y_S_7M */}
            <Route exact path="/dashboard-publico/:token" component={DashboardPublicPage} />
            <Route path="/usuario/:edit?/:id?" component={UserPage} />
            <Route path="/corretor/:edit?/:id?" component={RealtorPage} />
            <Route path="/eventos/:edit?/:id?" component={EventPage} />
            <Route path="/relatorio/:edit?/:id?" component={ReportPage} />
            <Route path="/cronograma/:edit?/:id?" component={SchedulePage} />
            <Route path="/diarias/:edit?/:id?" component={DiariesPage} />
            <Route path="/restricao-do-corretor/:edit?/:id?" component={RealtorRestrictionPage}/>
            <Route
                exact
                path="/restricao-geral/:edit?/:id?"
                render={(props) => (
										<GeneralRestrictionsPage {...props} isAdmin={isAdmin} />
									)}
            />
            <Route path="/deslogar" component={() => authContext.signOut(true)} />
            <Route component={NotFoundPage} />
        </Switch>
    );


    // add the context in all the project no more redux.. finally
    return (
        <ToastProvider>
            <AuthContext.Provider value={authContext}>
                <Router>
                        {userToken ?
                            <>
                                <Menu page={<AppStack />} isAdmin={isAdmin} />
                            </>
                         : (
                            <AuthStack />
                        )}
                </Router >
            </AuthContext.Provider>
        </ToastProvider>
    );
}

export default App;
