import React from 'react';

const NotFoundPage = () => {
    return (
		<>
            <h1>Não encontrado!</h1>
		</>
	);
};

export default NotFoundPage;