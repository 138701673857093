import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button'
import { onlyUnique, toastInfo } from '../../../../util/helpers'; 
import Axios from 'axios';

const SelectGroup = (props) => {
  const { changeSelect, dados } = props;
  const [data, setData] = useState('Todos');
  const [corretor, setCorretor] = useState('Todos');
  const [local, setLocal] = useState('Todos');

  const handleSelectChange = (event) => {
    changeSelect(event.target.name, {name: event.target.name, value: event.target.value});
    switch(event.target.name) {
      case "data":
        setData(event.target.value) 
        break;
      case "corretor":
        setCorretor(event.target.value)
        break;
      case "local":
        setLocal(event.target.value)
        break;
    }
  }

  const handleClick = () => {
    Axios.post(
      'diary/generate'
    )
    .then(
      response => {
        toastInfo('success', 'change');
      }
    )
    .catch(
      error => {
        toastInfo('error', 'custom');
      }
    )
    
  }

  return (   
    <div className="select-group">
      <FormControl
        className="container-item"
      >
        <InputLabel className="menu-label" id="demo-simple-select-label">Data</InputLabel>
        <Select
          name="data"
          // labelId="demo-simple-select-label"
          value={data}
          onChange={handleSelectChange}
        >
          <MenuItem className="menu-item" value={'Todos'}>Todos</MenuItem>
          {dados.map(dado => dado.date).filter(onlyUnique).sort().map((dataFinal, index) => (
            <MenuItem className="menu-item" key={index} value={dataFinal}>{dataFinal}</MenuItem>
          ))}
          
        </Select>
      </FormControl>
      <FormControl
        className="container-item"
      >
        <InputLabel className="menu-label" id="demo-simple-select-label">Corretor</InputLabel>
        <Select
          name="corretor"
          // labelId="demo-simple-select-label"
          value={corretor}
          onChange={handleSelectChange}
        >
          <MenuItem className="menu-item" value={'Todos'}>Todos</MenuItem>
          {dados.map(dado => dado.Realtor.Name).filter(onlyUnique).sort().map(corretorFinal => (
            <MenuItem className="menu-item" key={corretorFinal} value={corretorFinal}>{corretorFinal}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className="container-item"
      >
        <InputLabel className="menu-label" id="demo-simple-select-label">Local</InputLabel>
        <Select
          name="local"
          // labelId="demo-simple-select-label"
          value={local}
          onChange={handleSelectChange}
        >
          <MenuItem className="menu-item" value={'Todos'}>Todos</MenuItem> 
          {dados.map(dado => dado.Schedule.Event.Description).sort().filter(onlyUnique).map(localFinal => (
            <MenuItem className="menu-item" key={localFinal} value={localFinal}>{localFinal}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <div className="container-item">
        {/* <Button variant="contained" color="primary" className="button" onClick={handleClick}>
          <div className="button__text">Gerar</div>
        </Button>    */}
      </div> 
    </div> 
  )
}

export default SelectGroup;