import React from 'react';
import { useParams } from 'react-router-dom';

import GeneralRestrictionsListPage from './GeneralRestrictionsList';
import GeneralRestrictionsEditPage from './GeneralRestrictionsEdit';

const GeneralRestrictionsPage = (navigation) => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <GeneralRestrictionsEditPage id={params.id} navigation={navigation} />
	} else {
		return <GeneralRestrictionsListPage />	
	}
};

export default GeneralRestrictionsPage;