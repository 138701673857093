import React, { Component } from 'react';
import { isLoading, convertDateToGo, convertDateISOToBR, numberDayToString, convertDateISOToBROnlyHour, convertDateISOToBROnlyDate } from '../../../../util/helpers';
import Axios from 'axios';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
// import Tooltip from '../../../../util/Tooltip';
// import Tooltip from '../../../../util/Tooltip';
import Modal from 'react-modal';

import DatePicker, { registerLocale } from "react-datepicker";

import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';



import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);

require('@fullcalendar/core/main.min.css');
require('@fullcalendar/timegrid/main.css');
require('@fullcalendar/daygrid/main.css');
require('@fullcalendar/timeline/main.css');
require('@fullcalendar/resource-timeline/main.css');
// var Tooltip = require('Tooltip');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
class DashboardMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            calendarWeekends: true,
            local: [],
            events: [],
            modalIsOpen: false,
            allSchedules: [],
            ScheduleIDSelected: 0,
            startDate: new Date(),
            endDate: new Date(),

        }
        this.handleGetAllDiaries = this.handleGetAllDiaries.bind(this);
        this.handleGetAllEvents = this.handleGetAllEvents.bind(this);
        this.handleChangesDiaries = this.handleChangesDiaries.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleClickEvent = this.handleClickEvent.bind(this);
        this.handleTooltipEl = this.handleTooltipEl.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleAllSchedule = this.handleAllSchedule.bind(this);
        this.requestChangeDragDiary = this.requestChangeDragDiary.bind(this);
        this.handleGenerateDiary = this.handleGenerateDiary.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleGetAllDiaries();
        this.handleGetAllEvents();
        this.eventInfo = '';
    }
    calendarComponentRef = React.createRef();

    handleModal(type, similarItem = null) {
        if (type) {
            this.setState({ modalIsOpen: true });
            this.handleAllSchedule(similarItem);
        }
        if (!type) {
            this.setState({
                modalIsOpen: false,
                local: [],
                events: []
            });
            this.handleGetAllDiaries();
            this.handleGetAllEvents();
        }
    }

    formatDate(data) {
        return data.map((el, i) => {
            data[i].StartAtFormated = convertDateISOToBROnlyHour(el.StartAt);
            data[i].EndAtFormated = convertDateISOToBROnlyHour(el.EndAt);
            data[i].WeekdayFormated = el.Weekday != null ? numberDayToString(el.Weekday) : convertDateISOToBROnlyDate(el.StartAt);
        });
    }
    handleAllSchedule(similarItem) {
        // Axios.get(
        //     `schedule?page=0&limit=${this.limitPage}&order=id asc`,
        // ).then(response => {
        // 	let realData = response.data || [];
        //     loggout(realData);
        //     this.formatDate(realData);
        //     this.setState({
        //         allSchedules: realData,
        //         // count: realData.Count
        // 	});
        // 	console.log(this.state.allSchedules);
        //     isLoading('none');
        // }).catch(error => {
        //     toastInfo('error', 'custom')
        //     console.log("erro", error, error.response);
        //     isLoading('none');
        // });
        Axios
            .get("schedule")
            .then((response) => {
                var AllSchedules = [];
                let realData = response.data || [];
                this.formatDate(realData);
                for (var ac = 0; ac < realData.length; ac++) {

                    // console.log(realData[ac].Name.toLowerCase(), similarItem.toLowerCase(), realData[ac].Name.toLowerCase().search(similarItem.toLowerCase()));
                    if (realData[ac].Name.toLowerCase().search(similarItem.toLowerCase()) == 0) {
                        AllSchedules.push(
                            <option
                                key={realData[ac].ID}
                                value={realData[ac].ID}
                            >
                                {`${realData[ac].Name} | ${realData[ac].WeekdayFormated} | ${realData[ac].StartAtFormated} - ${realData[ac].EndAtFormated}`}
                            </option>
                        );
                    }

                }
                this.setState({ schedules: AllSchedules });
                isLoading("none");
            })
            .catch((error) => {
                // toastInfo("error", "custom");
                console.log("erro", error, error.response);
                isLoading("none");
            });
    }

    handleGetAllDiaries() {
        Axios.get(
            'diary',
        ).then(response => {
            let realData = response.data || [];
            realData.map((el, i) => {
                realData[i].id = el.ID;
                realData[i].resourceId = el.Schedule.EventID;
                realData[i].start = el.StartAt;
                realData[i].end = el.EndAt;
                realData[i].title = el.Realtor.Name;
            });
            this.setState({
                events: realData,
            });
        }).catch(error => {
            console.log("erro", error, error.response);
        });
    }
    handleGetAllEvents() {
        Axios.get(
            'event',
        ).then(response => {
            let realData = response.data || [];
            realData.map((el, i) => {
                realData[i].id = el.ID;
                realData[i].title = el.Name;
                realData[i].eventColor = el.Color;
            });
            this.setState({
                local: realData,
            });
            isLoading('none');
        }).catch(error => {
            // toastInfo('error', 'custom')
            console.log("erro", error, error.response);
            isLoading('none');
        });
    }
    handleChangesDiaries(e, type = false) {
        var objInputs = {
            ID: Number(e.event.id),
            StartAt: convertDateToGo(e.event.start),
            EndAt: convertDateToGo(e.event.end),
            RealtorID: e.event._def.extendedProps.Realtor.ID,
            // ScheduleID: e.event.extendedProps.ScheduleID,
            // ScheduleID: enviar o id do schedule, ai vai permitir fazero drag entre
        };
        this.setState({ infoLastedDrag: objInputs });
        if (e.newResource) {
            this.handleModal(true, e.newResource._resource.title);
        }
        else {
            this.requestChangeDragDiary(e);
        }
        // console.log(e.event._def.extendedProps.Realtor.ID, e.newResource._resource.title);
        return;
        // if(type == 'drag' && e.newResource != null ) objInputs.ScheduleID = Number(e.newResource.id);
        //se a pessoa tiver tentando trocar o local, como nao da pra saber qual o cronograma(schedule) nao pode
        // if (type == 'drag' && e.newResource != null) {
        // 	e.revert();
        // }

    }
    requestChangeDragDiary(e) {
        let objInputs = this.state.infoLastedDrag;
        if (e.event != undefined) {
            objInputs.ScheduleID = e.event.extendedProps.ScheduleID;
        } else {
            objInputs.ScheduleID = Number(this.idSchedule.value);
        }
        // console.log(objInputs);
        // return;
        Axios.patch(
            'diary',
            this.state.infoLastedDrag,
        ).then(response => {
            isLoading('none');
            // toastInfo('success', 'change');
            this.handleModal(false);
            this.handleGetAllDiaries();
            this.handleGetAllEvents();
            // console.log(response.data);
        }).catch(error => {
            isLoading('none');
            // toastInfo('error', 'custom');
            console.log("erro", error);
        });
    }
    handleDrag(e) {
        if (localStorage.getItem('@Bortolini::is_admin') == 'true') {
            this.handleChangesDiaries(e, 'drag');
        } else {
            return false;
        }
    }
    handleResize(e) {
        if (localStorage.getItem('@Bortolini::is_admin') == 'true') {
            this.handleChangesDiaries(e);
        } else {
            return false;
        }
    }
    handleClickEvent(e) {
        //se for admin vai pra pagina de diarias, se nao, nao faz nada
        if (localStorage.getItem('@Bortolini::is_admin') == 'true') this.props.history.replace('/diarias/edit/' + e.event.id);
        // this.props.props.history.replace('/diarias/edit/' + e.event.id);
    }
    // handleTooltipEl(e) {
    // 	console.log('====================================');
    // 	// console.log(e.el.children[0].children[0], el);
    // 	console.log('====================================');
    // 	let el = e.el.children[0].children[0];
    // 	// console.log(el, a);
    // 	// let a = el.querySelector('::after');
    // 	// console.log(a);
    // 	var color = window.getComputedStyle(
    // 		el, ':after'
    // 	);
    // 	console.log('====================================');
    // 	console.log(color);
    // 	console.log('====================================');
    // 	var tooltip = document.querySelectorAll('.coupontooltip');
    // 	document.addEventListener('mousemove', fn, false);

    // 	function fn(e) {
    // 		for (var i=tooltip.length; i--;) {
    // 			tooltip[i].style.left = e.pageX + 'px';
    // 			tooltip[i].style.top = e.pageY + 'px';
    // 		}
    // 	}
    // }
    handleTooltipEl(e) {
        let header = document.querySelector('header');
        if (e == 'leave') {
            header.style.zIndex = 1110;
            return;
        }
        header.style.zIndex = 1;
        // console.log('====================================');
        // console.log(e);
        // console.log('====================================');
        //adiciona o atributo de tooltip no evento ao passar o mouse por cima
        var att = document.createAttribute("data-tooltip");
        e.el.classList.add('tooltip-bottom');
        att.value = `Corretor: ${e.event.title} -
					Inicia: ${convertDateISOToBR(e.event.start)} -
					Termina: ${convertDateISOToBR(e.event.end)}`;
        // this.setState({eventInfo: att.value});
        e.el.setAttributeNode(att);
        // console.log('====================================');
        // console.log(att);
        // console.log('====================================');
    }
    // handleTooltipEl(e) {
    // 	if (e == 'leave') {
    // 		// console.log('====================================');
    // 		// console.log('leave');
    // 		// console.log('====================================');
    // 		this.eventInfo = '';
    // 	} else {
    // 		// console.log('====================================');
    // 		// console.log('fire');
    // 		// console.log('====================================');
    // 		//adiciona o atributo de tooltip no evento ao passar o mouse por cima
    // 		// var att = document.createAttribute("data-tooltip");
    // 		// e.el.classList.add('tooltip-bottom');
    // 		// att.value = `Corretor: ${e.event.title} -
    // 		// Inicia: ${convertDateISOToBR(e.event.start)} -
    // 		// Termina: ${convertDateISOToBR(e.event.end)}`;
    // 		this.eventInfo = 'fire';
    // 		// e.el.setAttributeNode(att);
    // 	}
    // }

    handleGenerateDiary() {
        isLoading('block');
        Axios.post(
            '/diary/generate',
            {
                StartAt: convertDateToGo(this.state.startDate),
                EndAt: convertDateToGo(this.state.endDate),

            },
            { validateStatus: (status) => { return true; } },
        ).then(response => {
            if (response.status == 500) {
                alert('Ocorreu um erro. Erro: ' + response.data.error);
                console.log("erro", response.data.error);
            } else {
                this.handleGetAllDiaries();
                this.handleGetAllEvents();
                alert('Criado com sucesso!');
            }

        }).catch(error => {
            // isLoading('none');
            // toastInfo('error', 'custom');
            console.log("erro", error);
        });

    }

    handleChangeStart = date => {
        this.setState({
            startDate: date
        });
    };
    handleChangeEnd = date => {
        this.setState({
            endDate: date
        });
    };

    render() {
        const optionToDayView = {
            columnFormat: 'ddd D.M',
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
        };
        const generateDiary = (
            <>
                <br />
                <br />
                <br />
                <Paper className="paper">
                    <Typography variant="h6">
                        Gerar Calendário
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <div className="general__input">
                                <span>Data Início</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <DatePicker
                                    className="input__generate"
                                    selected={this.state.startDate}
                                    onChange={date => this.handleChangeStart(date)}
                                    ref={(date) => { this.date = date }}
                                    dateFormat="d MMMM yyyy"
                                    locale="pt-BR"
                                />
                            </div>
                            {/* </Grid> */}
                            {/* <Grid item xs={12} sm={6}> */}
                            <div className="general__input">
                                <span>Data Término</span>&nbsp;&nbsp;
                                <DatePicker
                                    className="input__generate"
                                    selected={this.state.endDate}
                                    onChange={date => this.handleChangeEnd(date)}
                                    ref={(date) => { this.date = date }}
                                    dateFormat="d MMMM yyyy"
                                    locale="pt-BR"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" aria-label="delete" color="primary" onClick={this.handleGenerateDiary}>
                                Gerar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        );
        return (

            <div className="demo-app dashboard">
                {/* <Tooltip placement="top" trigger="hover" tooltip="Hi there!">
					Click me
				</Tooltip>
				{this.eventInfo}

				<p>Data attribute only <a href="#" data-tooltip="I’m the tooltip text">Tooltip</a></p>
						<p><code>.tooltip</code> <a href="#" className="tooltip" data-tooltip="I’m the tooltip text.">Tooltip</a></p> */}


                {/* <button onClick={() => this.handleModal(true)}>Open Modal</button> */}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    // onRequestClose={closeModal}
                    // shouldCloseOnOverlayClick={true}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <button className="close__modal" onClick={() => this.handleModal(false)}>X</button>
                    <section>
                        <div className="general__input">
                            <span>Cronograma que o selecionado deve ser inserido:</span>
                            <select
                                ref={(idSchedule) => {
                                    this.idSchedule = idSchedule;
                                }}
                                onChange={(e) =>
                                    this.setState({
                                        ScheduleIDSelected: e.target.value,
                                    })
                                }
                                value={this.state.ScheduleIDSelected}
                                name="event"
                            >
                                {this.state.schedules}
                            </select>
                        </div>
                        <span className="btn btn__200 m-t-1-r" onClick={(e) => { this.requestChangeDragDiary(e) }}>Salvar</span>
                    </section>
                </Modal>
                <div className="demo-app-calendar">
                    <FullCalendar
                        defaultView="resourceTimelineDay"
                        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                        aspectRatio={2.4}
                        editable={localStorage.getItem('@Bortolini::is_admin') == 'true' ? true : false}
                        editable={true}
                        locale={ptBrLocale}
                        header={{
                            left: "prev,next today",
                            center: "title",
                            // right: "dayGridMonth,timeGridWeek,timeGridDay,resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth"
                            // right: "dayGridMonth,resourceTimelineMonth,resourceTimelineDay,resourceTimelineWeek"
                            right: "dayGridMonth,resourceTimelineDay,resourceTimelineWeek"
                        }}
                        resourceLabelText={'Local'}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        resources={this.state.local}
                        eventLimit={true}
                        events={this.state.events}
                        eventDrop={(e) => { this.handleDrag(e) }}
                        eventResize={(e) => { this.handleResize(e) }}
                        eventClick={(e) => { this.handleClickEvent(e) }}
                        slotLabelFormat={optionToDayView}
                        eventMouseEnter={(e) => { this.handleTooltipEl(e) }}
                        eventMouseLeave={() => { this.handleTooltipEl('leave') }}
                        eventRender={(info) => {
                            // console.log('====================================');
                            // // console.log(info);
                            // console.log(info.event._def.extendedProps);
                            // console.log('====================================');

                            let el = info.el.children[0].children[0];
                            var att = document.createAttribute("data-tooltip");
                            el.classList.add('tooltip-bottom');
                            // att.value = ` Local: ${info.event._def.extendedProps.Schedule.Event.Name}
                            att.value = `Evento: ${info.event._def.extendedProps.Schedule.Name}
								Corretor: ${info.event.title}
								Inicia: ${convertDateISOToBR(info.event.start)}
								Termina: ${convertDateISOToBR(info.event.end)}`;
                            el.setAttributeNode(att);
                        }}
                    />
                    {/* <iframe className="iframe" src="https://app.powerbi.com/view?r=eyJrIjoiNGUyYzk2NjItNjVlMi00MDcwLWFiMDYtOTYwZGUzZDQ0YzQwIiwidCI6ImRjYmJlYTEwLWE1YWQtNGM4NS1hZGNhLTk5ZDM4ZDQxYTRjNSJ9" /> */}
                </div>
                {localStorage.getItem('@Bortolini::is_admin') == 'true' ? generateDiary : null}
                {/* {generateDiary} */}
            </div>
        )
    }
}

export default DashboardMobile;
