import React from 'react';
import ReactDOM from 'react-dom';
import './components/assets/scss/style.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom'
import { history } from "./components/service/history";


import Axios from 'axios';
import { urlBackend } from './components/util/helpers';
Axios.defaults.baseURL = urlBackend;

require('dotenv').config()


ReactDOM.render(
  <Router history={history}>
    {/* coment because warn error in tables, could be uncoment to production */}
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
