import React from 'react';
import { useParams } from 'react-router-dom';

import ScheduleListPage from './ScheduleList';
import ScheduleEditPage from './ScheduleEdit';

const SchedulePage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <ScheduleEditPage id={params.id} />
	} else {
		return <ScheduleListPage />	
	}
};

export default SchedulePage;