import React, { Component } from 'react';
import DashboardDesktop from "./desktop";
import DashboardMobile from "./mobile";
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false,
        }
    }

    componentDidMount() {
        if(window.window.innerWidth <= 600) {
            this.setState({isMobile: true});
        }
	}

    render() {
        return (
            <>
                {
                    this.state.isMobile ?
                    <DashboardMobile />
                    :
                    <DashboardDesktop />
                }
            </>
        )
    }
}

export default Dashboard;
