import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ReportEditPage = () => {

    return (
        <Container maxWidth="lg">
            <Paper className="paper" className="form-container">
                <Typography variant="h6">Informações Primárias</Typography>
                <Grid container spacing={1} sm={12}>
                    <Grid item xs={12} sm={8}>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-number"
                                label="Nome"
                                type="text"
                                margin="normal"
                                variant="outlined"
                                defaultValue=""
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="SQL"
                                multiline
                                rows={4}
                                type="text"
                                margin="normal"
                                variant="outlined"
                                defaultValue=""
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h7">Variáveis</Typography>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-number"
                                label="Nome do Filtro"
                                type="text"
                                margin="normal"
                                variant="outlined"
                                defaultValue=""
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                defaultValue=""
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-outlined-label">Tipo de Variável</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    // value={age}
                                    // onChange={handleChange}
                                    label="Tipo de Variável"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    // checked={state.checkedB}
                                    // onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Primary"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-number"
                                label="Nome do Filtro"
                                type="text"
                                margin="normal"
                                variant="outlined"
                                defaultValue=""
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ReportEditPage;