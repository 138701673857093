import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import TableAdac from '../../../../util/TableAdac';
import { convertDateISOToBROnlyDate, convertDateISOToMaterialUIFormatTime, getActualDateToTable } from '../../../../util/helpers';

const DashboardMobile = () => {

    const [dateTable, setDateTable] = useState({
        startAt: getActualDateToTable(true),
        endAt: getActualDateToTable(false)
    });
    const [key, setKey] = useState(0);

    let formSchema = yup.object().shape({
        StartAt: yup.string().required('Preencha a data inicial'),
        EndAt: yup.string().required('Preencha a data final'),
    });
    const { control, handleSubmit, errors, setValue } = useForm({
        validationSchema: formSchema
    });
    const onSubmit = (values) => {
        setDateTable({
            startAt: values.StartAt + 'T00:00:00-03:00',
            endAt: values.EndAt + 'T23:59:59-03:00',
        });
        setKey(key + 1);
    }
    return (
        <Paper className="paper" className="form-container">
            <FormControl variant="outlined" fullWidth margin="normal">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                            <Controller
                                as={TextField}
                                control={control}
                                type="date"
                                name="StartAt"
                                label="Data Inicial"
                                margin="normal"
                                defaultValue={dateTable.startAt}
                                variant="outlined"
                                fullWidth
                                error={errors.StartAt ? true : false}
                                helperText={errors.StartAt && errors.StartAt.message}
                                InputProps={{ inputProps: { min: "2020-12-01", max: "2100-01-01" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Controller
                                as={TextField}
                                control={control}
                                type="date"
                                name="EndAt"
                                defaultValue={dateTable.endAt}
                                label="Data Final"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={errors.EndAt ? true : false}
                                helperText={errors.EndAt && errors.EndAt.message}
                                InputProps={{ inputProps: { min: "2020-12-01", max: "2100-01-01" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className="d-f-a-i-c">
                            <Button type="submit" variant="outlined" aria-label="delete" color="primary" size="large">Buscar</Button>
                        </Grid>
                    </Grid>
                </form>
            </FormControl>
            <TableAdac
                key={key}
                urlApi="diary"
                urlFrontend="diarias"
                // startAt={'2021-02-01T00:00:00-03:00'}
                // endAt={'2021-02-10T23:59:59-03:00'}
                startAt={dateTable.startAt}
                endAt={dateTable.endAt}
                columnsArr={[
                    {
                        name: "ID",
                        label: "ID",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "Realtor.Name",
                        label: "Corretor",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "Schedule.Event.Name",
                        label: "Evento",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "Date",
                        label: "Data",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "StartAt",
                        label: "Início",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "EndAt",
                        label: "Final",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                ]}
                funcsColumn={{
                    Date: (el) => convertDateISOToBROnlyDate(el.StartAt),
                    StartAt: (el) => convertDateISOToMaterialUIFormatTime(el.StartAt),
                    EndAt: (el) => convertDateISOToMaterialUIFormatTime(el.EndAt),
                }}
            />
        </Paper>
    );
};

export default DashboardMobile;
